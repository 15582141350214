<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    <div class="card-header ">
                        <div class="card-title">
                           <h3 class="card-label">{{title}}
                                <small class="">{{subTitle}}</small>   
                            </h3> 
                        </div>

                        <div class="card-toolbar" v-if="currentPage != Page.FACULTAD">
                            <Button label="Atrás" icon="pi pi-chevron-left" class="p-button-text btn-light-primary"  @click="back_onClick()"/>
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="md:flex sm:inline-block">
                       
                        <div class="flex-none mr-1 w-11rem" v-if="currentPage == Page.ASIGNATURAS">
                                <span class="text-lg">Plan Académico</span>
                                <div v-for="plan in planAcademicoList" :key="plan" class="mx-3  my-2 flex justify-content-between align-items-center">
                                    <div class="my-2">
                                        <a href="javascript:void(0)" class="font-bold mr-2 text-gray-800 hover:text-primary" @click="GetAsignaturaByPlan(plan)">
                                            <i :class="'pi pi-fw pi-file ' + (plan.pla_vigente === 'N' ? 'text-danger' : '')"></i>
                                            {{plan.pla_nombre}}</a>
                                    </div>
                                    <a href="javascript:void(0)" class="font-bold mr-2 text-gray-800 " @click="editPlan_onClick(plan)">
                                        <i class="pi pi-pencil hover:text-primary"/>
                                    </a>
                                </div>
                            <!-- <Menu :model="menuModelPlan" v-if="currentPage == Page.ASIGNATURAS" >
                                <template #item="{item}">
                                   
                                </template>
                            </Menu> -->
                        </div>
                        
                        <div class="flex-grow-1 flex">
                            <!-- Begin : Facultades -->
                            <DataTable :value="facultadList" :rowHover="true"  stripedRows  responsiveLayout="stack" selectionMode="single" v-if="currentPage==Page.FACULTAD"
                                @row-dblclick="rowFac_dblClick($event)"
                            >
                                <Column field="fac_codigo" header="Código" headerStyle="width: 75px"/>
                                <Column field="fac_nombre" header="Facultad"/>
                                <Column field="fac_nombre_corto" header="Abreviado" />
                                <Column headerStyle="width:100px">
                                    <template #body="{data}">
                                        <div class="flex justify-content-end flex-shrink-0">
                                            <a href="javascript:void(0)" class="btn btn-bg-light p-2 text-primary" @click="viewCarreras_onClick(data)"> 
                                                <i class="pi pi-chevron-right"></i>
                                            </a>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>

                            <!--Begin: Carreras -->
                            <DataTable :value="carreraList" :rowHover="true" stripedRows  responsiveLayout="stack" selectionMode="single" v-if="currentPage==Page.CARRERA" 
                                @row-dblclick="rowCarr_dblClick($event)"
                            >
                                <Column field="car_codigo" header="Código" headerStyle="width: 75px"/>
                                <Column field="car_nombre" header="Carrera"/>
                                <Column field="car_competencia" header="Propósito de la carrera"/>
                                <Column headerStyle="width:100px">
                                    <template #body="{data}">
                                        <div class="flex justify-content-end flex-shrink-0">
                                            <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="editCarrera_onClick(data)" v-tooltip.top="'Editar'" > 
                                                <i class="pi pi-pencil"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-bg-light p-2" @click="viewAsignaturas_onClick(data)" v-tooltip.top="'Materias'" > 
                                                <i class="pi pi-chevron-right text-primary"></i>
                                            </a>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <!--End: Carreras -->

                            <!--Begin: Asignaturas -->
                            <DataTable :value="asignaturaList" :rowHover="true" stripedRows  responsiveLayout="stack" selectionMode="single"  v-if="currentPage==Page.ASIGNATURAS"
                                    :paginator="true" :rows="20" v-model:filters="filters1" :globalFilterFields="['plm_mat_nombre']"
                            >
                                <template #header>
                                    <div class="flex justify-content-between align-items-center mb-1">
                                        <span class="p-input-icon-left w-full">
                                            <i class="pi pi-search" />
                                            <InputText  placeholder="Nombre carrera"  class="w-full" v-model="filters1['global'].value"/>
                                        </span>
                                    </div>
                                </template>
                                <template #loading>
                                    Cargando Asignaturas. Espere por favor...
                                </template>
                                <template #empty>
                                    Ninguna asignatura asignada al plan.
                                </template>
                                <Column field="plm_codmat" header="Código" style="width:8rem"/>
                                <Column field="plm_num_mat" header="No." style="width:8rem"/>
                                <Column field="plm_mat_nombre" header="Asignatura">
                                    <template #body="{data}">
                                        <span class="font-semibold text-gray-800">{{data.plm_mat_nombre}}</span>
                                        <div class="mt-1 text-600 text-sm ">{{selectedPlan.pla_nombre}}</div>
                                    </template>
                                </Column>
                                
                                <Column field="plm_uv" header="U.V."  style="width:8rem"/>
                                <Column field="plm_horas_sem" header="Horas Semanal." class="text-center" style="width:12rem"/>
                               
                                <Column style="width:8rem">
                                    <template #body="{data}">
                                        <div class="flex justify-content-end flex-shrink-0">
                                            <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="editAsignatura_onClick(data)" v-tooltip.top="'Editar'" > 
                                                <i class="pi pi-pencil"></i>
                                            </a>
                                            
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <!--End: Asignaturas -->

                        </div>
                    </div>
                </template>
			</Card>
		</div>
	</div>

    <!--Begin: Dialgo-Edit Carrera -->
    <Dialog  v-model:visible="isDialogEditCarrera" :modal="true"  class="p-fluid" :style="{width: '480px'}">
        <template #header>
            <h5>Editar Carrera</h5>
        </template>
        <UpsertCarrera :Service="facultadService" :Carrera="selectedCarrera"  @save_onEmit="saveCarrera_onClick($event)" @cancel_onEmit="isDialogEditCarrera = false"/>
    </Dialog>
    <!--End: Dialog-Edit Careras -->

    <!--Begin: Dialgo-Edit Asignatura -->
    <Dialog  v-model:visible="isDialogAsignatura" :modal="true"  class="p-fluid" :style="{width: '480px'}">
        <template #header>
            <h5>Editar Asignatura</h5>
        </template>
        <UpsertAsignatura :Service="facultadService" :Asignatura="selectedAsignatura"  @save_onEmit="saveAsignatura_onClick($event)" @cancel_onEmit="isDialogAsignatura = false"/>
    </Dialog>
    <!--End: Dialog-Edit Asignatura -->

    <!--Begin: Dialgo-Edit Plan -->
    <Dialog  v-model:visible="isDialogPlan" :modal="true"  class="p-fluid" :style="{width: '640px'}">
        <template #header>
            <h5>Editar Plan Académico</h5>
        </template>
        <UpsertPlan :Service="facultadService" :PlanAcademico="selectedPlan"/>
    </Dialog>
    <!--End: Dialog-Edit Plan -->
</template>

<script>
import  FacultadService from '@/service/facultadService'

import UpsertAsignatura from '@/components/Generales/UpsertAsignatura.vue'
import UpsertCarrera from '@/components/Generales/UpsertCarrera.vue'
import UpsertPlan from '@/components/Generales/UpsertPlanAcademico.vue'

import {FilterMatchMode} from 'primevue/api';
const pages = {
        FACULTAD :   "facultades",
        CARRERA : "carreras",
        ASIGNATURAS : "asignaturas"
    }

export default {
    facultadService : null,
    components:{
        UpsertAsignatura,
        UpsertCarrera,
        UpsertPlan
    },
    data(){
        return{
            

            facultadList : [],
            selectedFacultad : {},
            carreraList : [],
            selectedCarrera :{},

            menuModelPlan : [], //for menu
            planAcademicoList :[],
            selectedPlan :  {},
            asignaturaList : [],
            selectedAsignatura : [],
           


            /*Controls*/
            title : "FACULTADES",
            subTitle :  "Administración de facultades",
            backPage : false,
            activePlanPanel : false,
            Page : pages,
            currentPage : pages.FACULTAD,
            previousPage : null,

            isDialogEditCarrera :  false,
            isDialogAsignatura :  false,
            isDialogPlan : false,
            submitted :  false,
            filters1 :  null
        }
    },
    created(){
        this.facultadService =  new FacultadService(this.axios)
        this.filters1 = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
          }
    },
    async mounted(){
        await this.facultadService.getAllFacultad().then(result => this.facultadList =  result)
    },
    methods : {
      
        back_onClick(){
            this.currentPage =  this.previousPage ;
        },

        async GetAsignaturaByPlan(data){
            this.asignaturaList = []
            this.selectedPlan = data;
            await this.facultadService.getAsignaturaByPlanAcad(this.selectedPlan.pla_codigo).then(result => this.asignaturaList =  result)
        },


        /*SEQUENCE: FACULTAD  TO  ==> CARRERA */
        async rowFac_dblClick(event){ //Double Click Row Facultad
            this.viewCarreras_onClick(event.data)
        },
        async viewCarreras_onClick(data){ //Click Row Icon Facultad
            this.selectedFacultad = data;
            await this.facultadService.getCarreraByFacultad(this.selectedFacultad.fac_codigo).then(result => this.carreraList =  result)
            if(Array.isArray(this.carreraList ) && this.carreraList.length){
                this.currentPage = this.Page.CARRERA;
                return;
            }
            this.$toast.add({severity:'warn', summary: 'Datos vacíos', detail:'No hay datos que mostrar', life: 3000});
        },


        /*SEQUENCE: CARRERA TO  ==> ASIGNATURA */
        async rowCarr_dblClick(event){ //Double Click Row Carrera
            this.viewAsignaturas_onClick(event.data)
        },
       
         async viewAsignaturas_onClick(data){ //Click Row Icon Carrera
            //Show: Asignaturas & Planes
            this.selectedCarrera =  data;
            await this.facultadService.getPlanByCarrera(this.selectedCarrera.car_codigo).then(result => this.planAcademicoList =  result)
            if(Array.isArray(this.planAcademicoList ) && this.planAcademicoList.length){
               
                this.currentPage = this.Page.ASIGNATURAS
                return;
            }
            this.$toast.add({severity:'warn', summary: 'Datos vacíos', detail:'No hay datos que mostrar', life: 3000});
           
        },


        async editCarrera_onClick(data){
            this.submitted  = false;
            this.isDialogEditCarrera =  true
            this.selectedCarrera = {...data};
        },

        async saveCarrera_onClick(event){
            var index = this.carreraList.findIndex(x=> x.car_codigo == event.car_codigo);
            if(index <= -1) return;

            this.carreraList[index] = event;
            this.isDialogEditCarrera = false;
            this.submitted = false;
        },

        async editAsignatura_onClick(data){
            this.submitted  = false;
            this.isDialogAsignatura  = true;
            this.selectedAsignatura =  {...data};
        },
        async saveAsignatura_onClick(event){
            var index = this.asignaturaList.findIndex(x=> x.plm_codigo == event.plm_codigo);
            if(index <= -1) return;
            
            this.asignaturaList[index] = event;
            this.isDialogAsignatura = false;
            this.submitted = false;
        },

        async editPlan_onClick(data){
            this.isDialogPlan =  true
            this.selectedPlan = {...data}
        }
    },
    watch: {
        currentPage: function (val) {
            switch(val){
                case this.Page.ASIGNATURAS :
                    this.previousPage =  this.Page.CARRERA;
                    this.title =  "ASIGNATURAS";
                    this.subTitle = this.selectedCarrera.car_nombre
                    break;
                case this.Page.CARRERA:
                    this.previousPage = this.Page.FACULTAD;
                    this.title =  "CARRERAS";
                    this.subTitle = this.selectedFacultad.fac_nombre
                    this.asignaturaList = []
                    break;
                default :
                    this.previousPage = "";
                    this.title =  "FACULTADES";
                    this.subTitle = "Administración de facultades";
                    break;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
button {
    margin-right: .5rem;
}
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}

.status-badge {
        color: var(--primary-color-text);
		&.status-Activo {
            background: #256029;
           
		}
		&.status-Inactivo {
			background: #C63737;
		}
		
	}
</style>