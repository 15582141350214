<template>
    <div class="field">
        <label for="plm_mat_nombre">Materia</label>
        <InputText id="plm_mat_nombre" v-model="selectedAsignatura.plm_mat_nombre" disabled  class="opacity-100"/>
    </div>
    <div class="flex align-items-center  justify-content-between flex-column sm:flex-row m-2">
        <Chip :label="'No. ' + selectedAsignatura.plm_num_mat" class="mr-2 mb-2 custom-chip" />
        <Chip :label="'UV. ' + selectedAsignatura.plm_uv"  class="mr-2 mb-2 custom-chip" />
        <Chip :label="'HS. ' + selectedAsignatura.plm_horas_sem"  class="mr-2 mb-2 custom-chip" />
    </div>
    <div class="field">
        <label for="plm_area_form">Área de formación  <small id="plm_area_form" class="p-error">* Afecta historico</small></label>
        <Textarea id="plm_area_form" v-model.trim="selectedAsignatura.plm_area_form" required="true" rows="5" cols="20" 
        :autoResize="true"  placeholder="Definir área de formación"  :class="{'p-invalid': submitted && !selectedAsignatura.plm_area_form}"/>
       
        <small id="plm_area_form" class="p-error" v-if="submitted && !selectedAsignatura.plm_area_form">Descriptor requerido.</small>
    </div>
 
    <div class="flex justify-content-end">
        <div class="flex-initial mx-1"><Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancel_onClick()"/></div>
        <div class="flex-initial mx-1"><Button label="Guardar" icon="pi pi-check"    @click="saveAsignatura_onClick()"/></div>
    </div>
        
    
</template>


<script>
export default {
    
    props:{
        Service : null,
        Asignatura:{
            type : Object,
            default(){
                return {}
            }
        }
    },
    emits:["save_onEmit", "cancel_onEmit"],
    data(){
        return {
            submitted :  false,
            selectedAsignatura : {...this.Asignatura}
        }
    },
    methods:{
        cancel_onClick(){
            this.$emit('cancel_onEmit')
        },
        async saveAsignatura_onClick(){
            try {
                this.$confirm.require({
                message: `¿Seguro que desea modificar está asignatura? Los cambios se verán reflejados en todo los planes anteriores.`,
                header: 'Actualizar Asignatura',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    this.submitted  = true;
                    await this.Service.updateAsignatura(this.selectedAsignatura).then(result => this.selectedAsignatura =  result);
                    this.$toast.add({severity:'success', summary: '¡Actualizado!', detail:'Competencia actualizada', life: 1500});
                    this.$emit('save_onEmit', this.selectedAsignatura)
                },
            });
            }catch(ex){
                this.$toast.add({severity:'error', summary: '¡Error al actualizar!', detail:'Msg' + ex, life: 1500});  
            }
        },
    }
}
</script>



<style lang="scss" scoped>
button {
    margin-right: .5rem;
}
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}

.status-badge {
        color: var(--primary-color-text);
		&.status-Activo {
            background: #256029;
           
		}
		&.status-Inactivo {
			background: #C63737;
		}
		
	}
</style>