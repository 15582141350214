<template>
    <div class="field">
        <label for="car_nombre">Carrera</label>
        <InputText id="car_nombre" v-model="selectedCarrera.car_nombre" disabled  class="opacity-100"/>
    </div>
    <div class="flex align-items-center  justify-content-between flex-column sm:flex-row m-2">
        <Chip :label="Grado[selectedCarrera.car_grado]" class="mr-2 mb-2 custom-chip" />
        <Chip :label="Modalidad[selectedCarrera.car_modalidad]"  class="mr-2 mb-2 custom-chip" />
        <Chip :label="Estado[selectedCarrera.car_estado]" class="mr-2 mb-2"  :class="'status-badge status-'+ Estado[selectedCarrera.car_estado]" />
    </div>
    <div class="field">
        <label for="car_competencia">Propósito de la carrera <small id="plm_area_form" class="p-error">* Afecta historico</small></label>
        <Textarea id="car_competencia" v-model.trim="selectedCarrera.car_competencia" required="true" rows="10" cols="20" 
        :autoResize="true"  placeholder="Definir competencia de carrera"  :class="{'p-invalid': submitted && !selectedCarrera.car_competencia}"/>
        <small id="car_competencia" class="p-error" v-if="submitted && !selectedCarrera.car_competencia">Descriptor requerido.</small>
    </div>
    <div class="flex justify-content-end">
        <div class="flex-initial mx-1"><Button label="Guardar" icon="pi pi-check" class=""  @click="saveCarrera_onClick()"   /></div>
        <div class="flex-initial mx-1"><Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancel_onClick()"/></div>
    </div>
    
</template>

<script>
export default {
    
    props:{
        Service : null,
        Carrera:{
            type : Object,
            default(){
                return {}
            }
        }
    },
    emits:["save_onEmit", "cancel_onEmit"],
    data(){
        return{
            Grado : {
                U :  "Universitario",
                M : "Maestria"
            },

            Modalidad : {
                P : "Presencial",
                V :  "Virtual",
                S : "Semi-Presencial"
            },

            Estado: {
                A : "Activo",
                I : "Inactivo"
            },
            submitted :  false,
            selectedCarrera : {...this.Carrera}
        }
    },
    methods:{
        cancel_onClick(){
            this.$emit('cancel_onEmit')
        },
        async saveCarrera_onClick(){
            try {
                this.$confirm.require({
                message: `¿Seguro que desea modificar está carrera? Los cambios se verán reflejados en todo los planes anteriores.`,
                header: 'Actualizar Carrera',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    this.submitted  = true;
                    await this.Service.updateCarrera(this.selectedCarrera).then(result => this.selectedCarrera =  result)
                    this.$toast.add({severity:'success', summary: '¡Actualizado!', detail:'Carrera actualizada', life: 1500});
                    this.$emit('save_onEmit', this.selectedCarrera)
                },
            });
            }catch(ex){
                this.$toast.add({severity:'error', summary: '¡Error al actualizar!', detail:'Msg' + ex, life: 1500});  
            }
        },
    }
}
</script>


<style lang="scss" scoped>
button {
    margin-right: .5rem;
}
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}

.status-badge {
        color: var(--primary-color-text);
		&.status-Activo {
            background: #256029;
           
		}
		&.status-Inactivo {
			background: #C63737;
		}
		
	}
</style>